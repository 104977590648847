@charset "UTF-8";
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-count {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-count .sb-count {
  padding-right: 1em;
  padding-left: 0;
}
.sb-material-dark .sb-wrapper.sb-show-text.sb-show-count .sb-text {
  padding-right: 0.5em;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-default .sb-wrapper {
  color: white;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--button-color) !important;
}
.sb-default .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-default .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-default .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-default .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-default .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-default .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-default .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-default .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-default .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-default .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-default .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-default .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-default .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-default .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-default .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-default .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-default .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-default .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-default .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
  padding-right: 1em;
  padding-left: 0;
}

body {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
  background-color: #fff;
  color: #4d4d4d;
}

.mobile {
  display: none !important;
}

a {
  cursor: pointer;
}

header#mainHeader {
  background: var(--nav-background) url(/assets/header_bg.png) right center no-repeat;
  height: inherit;
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 0px;
  position: fixed;
  z-index: 100;
  background-position-x: 102.8%;
}
header#mainHeader .container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  z-index: 4;
  width: 100%;
  max-width: inherit;
  margin: 0px;
}
header#mainHeader .container .mat-toolbar {
  margin-top: 15px;
  margin-left: 108px;
  margin-right: 135px;
  background: #fff;
  border-top-left-radius: 50px;
  height: calc(110px - 15px);
}
header#mainHeader .container div.toolbarContainer {
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: flex-start;
  flex: 1 1 0%;
}
header#mainHeader .container div.logoContainer {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  flex: 1 0 auto;
}
header#mainHeader .container div.menuContainer {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  flex: 0 1 auto;
}
header#mainHeader .container div.accountButton {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  color: var(--primary-color-contrast);
}
header#mainHeader .toolbar-separator {
  height: 64px;
  width: 1px;
  background: rgba(0, 0, 0, 0.12);
}
header#mainHeader .mat-button {
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  header#mainHeader .mat-button {
    padding-right: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  header#mainHeader .mat-button {
    padding-right: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  header#mainHeader .mat-button {
    padding-right: 5px;
  }
}
header#mainHeader .menu {
  margin-right: 5%;
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  letter-spacing: -0.1px !important;
  text-transform: uppercase;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  header#mainHeader .menu {
    margin-right: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  header#mainHeader .menu {
    margin-right: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  header#mainHeader .menu {
    margin-right: 5px;
    padding-right: 1px;
  }
}
header#mainHeader .menu > div {
  margin-left: 20px;
  color: var(primary-color-contrast);
  height: 80px;
  align-items: center;
  display: flex;
}
header#mainHeader .menu > div a {
  color: var(--primary-color-contrast);
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  header#mainHeader .menu > div {
    margin-left: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  header#mainHeader .menu > div {
    margin-left: 5px;
  }
}
header#mainHeader .menu .main-item {
  margin-right: 50px;
  cursor: pointer;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  header#mainHeader .menu .main-item {
    margin-right: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  header#mainHeader .menu .main-item {
    margin-right: 5px;
    font-size: 7px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  header#mainHeader .menu .main-item {
    margin-right: 5px;
    font-size: 7px;
  }
}
header#mainHeader .menu .greeting {
  color: #fff;
  display: flex;
  align-items: center;
}
header#mainHeader .menu .greeting .hamburger {
  background: #fff;
  display: block;
  height: 3px;
  position: relative;
  width: 22px;
  margin-left: 20px;
}
header#mainHeader .menu .greeting .hamburger:before, header#mainHeader .menu .greeting .hamburger:after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}
header#mainHeader .menu .greeting .hamburger:before {
  top: 8px;
}
header#mainHeader .menu .greeting .hamburger:after {
  top: -8px;
}
header#mainHeader .menu.below {
  z-index: 2;
}
header#mainHeader .menu .mat-toolbar {
  position: relative;
  background: inherit !important;
  color: inherit !important;
  padding: 0 !important;
}
header#mainHeader .menu .mat-toolbar .user-button,
header#mainHeader .menu .mat-toolbar fuse-search-bar,
header#mainHeader .menu .mat-toolbar .language-button,
header#mainHeader .menu .mat-toolbar .chat-panel-toggle-button,
header#mainHeader .menu .mat-toolbar .quick-panel-toggle-button {
  min-width: 64px;
  height: 64px;
}
header#mainHeader .menu .mat-toolbar .navbar-toggle-button {
  min-width: 56px;
  height: 56px;
}

main {
  padding-top: 110px;
}

footer#dtc-main-footer {
  position: fixed;
  width: 100%;
  bottom: 118px;
  height: 20px;
  margin-top: 20px;
  background-color: var(--primary-color);
  z-index: 6;
}

select {
  font-weight: 300;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  color: #4d4d4d;
}

select:focus {
  outline: none;
}

.errorField {
  border-right: solid 5px #f00 !important;
}

.container {
  margin: 0 2%;
  position: relative;
}

mat-dialog-content {
  min-width: 400px !important;
}
mat-dialog-content div.dialogContent {
  min-height: 50px;
  padding-bottom: 12px;
}

mat-form-field {
  width: 100% !important;
}

mat-dialog-actions > * {
  margin-right: 8px !important;
}

.btn {
  width: 120px;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  background-color: var(--accent-color);
  /*
  &.red {
  	background:$red;
  	border:solid 1px $red;
  	&:hover {
  		background:$red80pct;
  	}
  }
  &.grey {
  	background:$grey;
  	border:solid 1px $grey;
  	&:hover {
  		background:$grey80pct;
  	}
  }
  &.orange {
  	background: $orange;
  	border:solid 1px $orange;
  	&:hover {
  		background:$orangeHover;
  	}
  }
  &.green {
  	background:$green;
  	border:solid 1px $green;
  	&:hover {
  		background:$green80pct;
  	}
  }
  &.blue {
  	background:$blue;
  	border:solid 1px $blue;
  	&:hover {
  		background:$blue80pct;
  	}
  }
  &.dark {
  	background:$dark;
  	border:solid 1px $dark;
  	&:hover {
  		background:$dark80pct;
  	}
  }
  */
}
.btn:hover {
  background-color: var(--accent-lighter-color);
  color: var(--neutral-color);
}
.btn.border {
  border: solid 1px var(--accent-color);
}
.btn.wide {
  width: 100%;
}
.btn.centered {
  margin: 0 auto;
}
.btn.next span:after {
  content: "";
  border-left: solid 6px #fff;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  margin-left: 8px;
  display: inline-block;
  vertical-align: text-bottom;
}
.btn.prev span:before {
  content: "";
  border-right: solid 6px #fff;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-bottom;
}
.btn.icon span:before {
  content: "";
  margin-right: 8px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.btn.icon:hover {
  background: #fec226;
  color: #fff;
}
.btn.stream span:before {
  content: "";
}
.btn.info span:before {
  content: "";
}
.btn.close span:before {
  content: "";
}
.btn.revert span:before {
  content: "";
}
.btn.preview span:before {
  content: "";
}
.btn.save span:before {
  content: "";
}
.btn.continue span:before {
  content: "";
}
.btn.add span:before {
  content: "";
}
.btn.new span:before {
  content: "";
}
.btn.send span:before {
  content: "";
}
.btn.back span:before {
  content: "";
}
.btn.back span:before {
  content: "";
}
.btn.processing span,
.btn.processing i {
  display: none;
}
.btn.processing .processButton {
  text-align: center;
  font-size: 26px;
  position: relative;
  width: 80px;
  margin: 0px auto;
}
.btn.processing .processButton > div {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #fff;
  margin: 0 3px;
  -webkit-animation: bouncedelay 0.7s infinite ease-in-out both;
  animation: bouncedelay 0.7s infinite ease-in-out both;
}
.btn.processing .processButton > div.one {
  animation-delay: -0.16s;
}
.btn.processing .processButton > div.two {
  animation-delay: -0.08s;
}
.btn i {
  margin-right: 12px;
}
.btn.disabled, .btn.disabled:hover {
  background: #e0e0e0;
  border: solid 1px #e0e0e0;
  cursor: default;
}
.btn.small {
  height: 34px;
  min-height: unset;
  line-height: 34px;
  border-radius: 5px;
}
.btn.auto {
  width: auto;
  height: auto;
  line-height: unset;
  min-height: unset;
  padding: 5px;
}

.errorMessageSnackbar {
  background-color: #f2dede !important;
  border-color: #ebcccc !important;
  color: #a94442 !important;
  font-weight: bold;
}
.errorMessageSnackbar button {
  color: #a94442 !important;
}

.errorAlert {
  position: fixed;
  top: 0;
  left: 20%;
  z-index: 1002;
  text-align: center;
  width: 60%;
  padding: 18px;
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
  margin: 10px;
  border-width: 3px;
  right: 0;
  border-radius: 5px;
}

.alert {
  padding: 0px 0px 13px 0px;
  font-size: 0.8em;
}
.alert.alert-danger {
  color: #f33;
}
.alert.alert-info {
  color: #65a0cd;
}

ng-progress .ng-bar-placeholder {
  height: 30px !important;
}
ng-progress .ng-bar-placeholder .ng-bar {
  background-color: var(--accent-color) !important;
}
ng-progress .ng-spinner {
  right: unset !important;
  left: 15px;
  top: 45px !important;
}
ng-progress .ng-meteor {
  display: none !important;
}

.error {
  color: #f00;
  font-family: "Work Sans";
  font-size: 12px;
  letter-spacing: 1.5px;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading > div {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: var(--primary-color);
  margin: 0 3px;
  -webkit-animation: bouncedelay 0.7s infinite ease-in-out both;
  animation: bouncedelay 0.7s infinite ease-in-out both;
}
.loading > div.one {
  animation-delay: -0.16s;
}
.loading > div.two {
  animation-delay: -0.08s;
}

div.fieldWrapper {
  position: relative;
  /*height:40px; removed for email coolness on personalize form */
  margin: 12px 12px 12px 0;
}
div.fieldWrapper.textarea {
  height: auto;
}
div.fieldWrapper label {
  position: absolute;
  top: 8px;
  left: 2px;
  background: #fff;
  padding: 0 10px;
  transition: 250ms;
  color: #65a0cd;
  font-style: italic;
}
div.fieldWrapper span {
  color: #65a0cd;
  font-style: italic;
}
div.fieldWrapper.required label:after {
  content: "*";
  position: relative;
  top: -4px;
}
div.fieldWrapper.required input[required]:valid + label:after,
div.fieldWrapper.required .hasText + label:after,
div.fieldWrapper.required input:focus + label:after,
div.fieldWrapper.required textarea[required]:valid + label:after {
  top: -2px;
}
div.fieldWrapper input[type=text],
div.fieldWrapper input[type=tel],
div.fieldWrapper input[type=email],
div.fieldWrapper input[type=password] {
  padding: 8px 12px;
  border: solid 1px #c7c8ca;
  width: 100%;
}
div.fieldWrapper input[required]:valid + label,
div.fieldWrapper input.hasText ~ label,
div.fieldWrapper textarea[required]:valid + label,
div.fieldWrapper .hasText label,
div.fieldWrapper select[required]:valid + label {
  font-size: 10px;
  transform: translateX(5px) translateY(-10px);
  color: #c7c8ca;
  font-style: normal;
}
div.fieldWrapper textarea:focus + label,
div.fieldWrapper input:focus ~ label,
div.fieldWrapper .selections:focus + label {
  font-size: 10px;
  transform: translateX(5px) translateY(-10px);
  color: #65a0cd;
  font-style: normal;
}
div.fieldWrapper .selections:focus {
  border: solid 4px orangered;
}
div.fieldWrapper textarea {
  padding: 10px;
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
  border: solid 1px #c7c8ca;
}

.fileUploadButton {
  position: relative;
}
.fileUploadButton input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.stylizedCheckboxWrapper {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}
.stylizedCheckboxWrapper input {
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 2;
  position: relative;
}
.stylizedCheckboxWrapper input:checked + span {
  border: solid 2px #8bc53f;
}
.stylizedCheckboxWrapper input:checked + span:after {
  color: #8bc53f;
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  font-size: 23px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.stylizedCheckboxWrapper span {
  border: solid 1px #c7c8ca;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
  z-index: 1;
}

div.fileActions,
div.folderActions {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background: #c7c8ca;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 6px;
  right: 6px;
  z-index: 5;
  align-items: center;
  cursor: pointer;
}
div.fileActions div,
div.folderActions div {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #fff;
  margin: 2px;
}

div.folderActions {
  width: 17px;
  height: 12px;
  position: relative;
  float: right;
  margin-left: 5px;
  bottom: unset;
  right: unset;
}
div.folderActions div {
  width: 3px;
  height: 3px;
  margin: 1px;
}

#fileSelector {
  display: flex;
}
#fileSelector .contentsFiles {
  display: flex;
  flex-wrap: wrap;
}
#fileSelector .contentsFiles > div {
  width: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#fileSelector .contentsFiles .fileName {
  padding-bottom: 4px;
  word-break: break-word;
}
#fileSelector .contentsFiles .fileName div {
  padding: 2px;
  border: solid 1px #fff;
  position: relative;
  font-size: 0.8em;
}
#fileSelector .contentsFiles .file {
  width: 94px;
  height: 121px;
  margin: 0 17px;
  position: relative;
  margin-bottom: 20px;
  box-sizing: content-box;
  border: solid 1px #000;
}
#fileSelector .contentsFiles .file img {
  max-width: 188px;
  max-height: 242px;
  margin: auto;
}
#fileSelector .contentsFiles .file a {
  width: 100%;
  height: 100%;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#fileSelector .contentsFiles .file .fileType {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: bold;
  font-size: 0.8em;
  border-top: solid 1px;
  border-right: solid 1px;
  padding: 4px;
  background: #fff;
  box-shadow: 2px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

li.noactions div.folderActions {
  display: none;
}

div.folderMenu {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 0;
  overflow: hidden;
  z-index: 10;
}
div.folderMenu.open {
  max-height: 30px;
  border: solid 1px #4d4d4d;
}
div.folderMenu ul {
  max-height: unset !important;
  margin-left: 0px !important;
}
div.folderMenu ul li {
  padding: 0px 12px;
  cursor: pointer;
  color: #4d4d4d;
}
div.folderMenu ul li.folderAction::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 20px;
  font-size: 20px;
}
div.folderMenu ul li.actionRemove::before {
  content: "";
}
div.folderMenu ul li.actionRename::before {
  content: "";
}
div.folderMenu ul li.actionMove::before {
  content: "";
}
div.folderMenu ul li:hover {
  color: #65a0cd;
}
div.folderMenu ul li.disabled {
  color: #e0e0e0;
}
div.folderMenu ul li.disabled:hover {
  background: #fff;
  cursor: default;
}

div.fileMenu {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 0;
  transition: max-height 500ms ease, border 1ms linear 550ms;
  overflow: hidden;
  z-index: 10;
}
div.fileMenu.open {
  max-height: 300px;
  border: solid 1px #4d4d4d;
  transition: max-height 500ms ease, border 0ms linear 0ms;
}
div.fileMenu ul li {
  padding: 8px 12px;
  cursor: pointer;
}
div.fileMenu ul li.fileAction::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 20px;
  font-size: 20px;
}
div.fileMenu ul li.actionAllowAddCart::before {
  content: "";
}
div.fileMenu ul li.actionAllowCopy::before {
  content: "";
}
div.fileMenu ul li.actionAllowDelete::before {
  content: "";
}
div.fileMenu ul li.actionAllowMove::before {
  content: "";
}
div.fileMenu ul li.actionAllowShare::before {
  content: "";
}
div.fileMenu ul li.actionAllowShareAll::before {
  content: "";
}
div.fileMenu ul li.open::before {
  content: "";
}
div.fileMenu ul li:hover {
  background: #65a0cd;
  color: #fff;
}
div.fileMenu ul li.disabled {
  color: #e0e0e0;
}
div.fileMenu ul li.disabled:hover {
  background: #fff;
  cursor: default;
}

ul.tabs {
  display: flex;
  padding: 0 10px 0 2px;
  border-bottom: solid 1px;
}
ul.tabs li {
  background: #ccc;
  color: #666;
  margin: 10px 0px 0;
  padding: 8px;
  border-radius: 8px 8px 0 0;
}
ul.tabs li.active {
  background: #65a0cd;
  color: #fff;
  font-weight: bold;
}

acc-main-dialog.small .popup {
  width: 30%;
  min-height: 28%;
}
acc-main-dialog.auto .popup {
  width: auto;
  min-height: unset;
  padding-bottom: 20px;
}
acc-main-dialog.textOnly .content {
  white-space: pre-wrap;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 70%;
  padding: 0;
  max-width: 861px;
  min-height: 50%;
  background: #fff;
  box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.6);
  max-height: 90vh;
  overflow: auto;
  padding-bottom: 20px;
}
.popup.small {
  width: 30%;
  min-height: 28%;
}
.popup.auto {
  width: auto;
  min-height: 245px;
  padding-bottom: 20px;
  height: auto;
  min-width: 310px;
}
.popup.medium {
  width: 37%;
}
.popup mat-toolbar {
  display: flex;
  justify-content: space-between;
}
.popup .titlebar {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  color: #fff;
  margin-bottom: 12px;
}
.popup .titlebar span {
  flex-grow: 1;
}
.popup .titlebar .close {
  flex-grow: 0;
  font-size: 22px;
}
.popup .content {
  padding: 20px;
  min-height: 105px;
  padding-bottom: 20px;
}
.popup .form {
  margin-left: 15px;
  margin-right: 15px;
}
.popup select {
  padding: 10px;
  width: 325px;
}
.popup .buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.popup .buttons .btn {
  margin: 5px;
}
.popup .bottomButtons {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

div.emailAddresses {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0 0;
}
div.emailAddresses .emailAddress {
  font-size: 11px;
  border: solid 1px #65a0cd;
  color: #65a0cd;
  padding: 3px 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  margin-top: -15px;
}
div.emailAddresses .emailAddress.invalid {
  border: solid 1px #be1e2d;
  color: #be1e2d;
}
div.emailAddresses .emailAddress.invalid span {
  color: #be1e2d;
}
div.emailAddresses .emailAddress span {
  font-style: normal;
  margin-left: 6px;
  cursor: pointer;
  font-size: 10px;
}
div.emailAddresses input[type=text] {
  padding: 0;
  border: 0;
  min-width: 170px;
  flex-grow: 1;
  width: auto;
  outline: none;
}

acc-common-email-selector,
acc-common-account-selector {
  display: block;
  position: relative;
}

.optionList {
  position: absolute;
  z-index: 5;
  background: #fff;
  cursor: pointer;
  max-height: 0px;
  transition: all 250ms linear;
  overflow: hidden;
  min-width: 90%;
  margin-top: -26px;
}
.optionList.open {
  max-height: 140px;
  border: solid 1px #c7c8ca;
  overflow: auto;
}
.optionList li {
  padding: 5px 5px 5px 10px;
}
.optionList li span.highlight {
  color: #be1e2d;
}
.optionList li.active {
  background: rgba(101, 160, 205, 0.8);
  color: #fff;
}

.docDetailsBottom .crm:before {
  content: "";
  color: #65a0cd;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 11px;
}
.docDetailsBottom .crm input {
  padding-left: 0 !important;
}
.docDetailsBottom acc-common-account-selector {
  width: 100%;
}
.docDetailsBottom acc-common-account-selector div {
  width: 100%;
}
.docDetailsBottom acc-common-account-selector div input {
  width: calc(100% - 25px);
}

span.highlight {
  color: #be1e2d;
}

.errorMessage {
  color: #f00;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.errorMessage.errorAlert:after {
  content: "";
  cursor: pointer;
  font-family: fontawesome;
}

.successMessage {
  columns: #00ff00;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.successMessage.errorAlert:after {
  content: "";
  cursor: pointer;
  font-family: fontawesome;
}

/*
.submitted .ng-invalid {
	border-right:solid 5px #f00 !important;
}
*/
.noEvents {
  pointer-events: none;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@media (max-width: 1025px) {
  .container {
    margin: 0 4%;
  }

  .mobile {
    display: flex !important;
  }
}
mat-dialog-container .libraryFolders {
  padding: 0 13px 0 0;
  flex-shrink: 0;
}
mat-dialog-container .libraryFolders ul {
  margin-left: 20px;
}
mat-dialog-container .libraryFolders ul li {
  margin: 10px 2px;
  position: relative;
}
mat-dialog-container .libraryFolders ul li div.folder {
  display: flex;
  color: #4d4d4d;
  cursor: pointer;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
mat-dialog-container .libraryFolders ul li div.folder u {
  margin-right: 10px;
  visibility: hidden;
  transition: all 50ms linear;
  color: #4d4d4d;
  display: inline-table;
  text-decoration: none;
}
mat-dialog-container .libraryFolders ul li div.folder.hasChildren u {
  visibility: visible;
}
mat-dialog-container .libraryFolders ul li div.folder.active {
  color: #65a0cd;
}
mat-dialog-container .libraryFolders ul li div.folder.active + div #clearCartBtn {
  color: #65a0cd;
}
mat-dialog-container .libraryFolders ul li div.folder i {
  margin-right: 12px;
}
mat-dialog-container .libraryFolders ul li ul {
  max-height: 0;
  transition: none;
  overflow: hidden;
}
mat-dialog-container .libraryFolders ul li.open > div u {
  transform: rotate(90deg);
}
mat-dialog-container .libraryFolders ul li.open > ul {
  max-height: unset;
}
mat-dialog-container div.dialogContent {
  max-height: 65vw;
}

.pushButtonsDown {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pushButtonsDown .spacer {
  flex-grow: 1;
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
}
.avatar.square {
  border-radius: 0;
}
.avatar.small {
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
}
.avatar.big {
  width: 72px;
  min-width: 72px;
  height: 72px;
  line-height: 72px;
}
.avatar.huge {
  width: 96px;
  min-width: 96px;
  height: 96px;
  line-height: 96px;
}

.avatar-wrapper {
  position: relative;
}
.avatar-wrapper .avatar {
  margin-top: 0;
  margin-bottom: 0;
}
.avatar-wrapper mat-icon.status {
  position: absolute;
  top: 28px;
  left: 28px;
}

mat-icon.status {
  border-radius: 50%;
}
mat-icon.status.online {
  color: #4caf50;
}
mat-icon.status.online:before {
  content: "check_circle";
}
mat-icon.status.do-not-disturb {
  color: #f44336;
}
mat-icon.status.do-not-disturb:before {
  content: "remove_circle_outline";
}
mat-icon.status.away {
  color: #ffc107;
}
mat-icon.status.away:before {
  content: "access_time";
}
mat-icon.status.offline {
  color: #646464;
}
mat-icon.status.offline:before {
  content: "not_interested";
}

#textLibrarySearch {
  width: 60vw;
}
#textLibrarySearch .results {
  margin-top: 15px;
}
#textLibrarySearch .results ul {
  max-height: 330px;
  overflow: auto;
}
#textLibrarySearch .results ul li {
  border: solid 2px transparent;
  padding-bottom: 8px;
  padding: 4px 15px;
}
#textLibrarySearch .results ul li h3 {
  font-weight: bold;
  padding-bottom: 3px;
}
#textLibrarySearch .results ul li p {
  overflow: hidden;
  position: relative;
  line-height: 1em;
  max-height: 4em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  cursor: pointer;
}
#textLibrarySearch .results ul li p::before {
  content: "...";
  position: absolute;
  right: 17px;
  bottom: 0;
  background: #fff;
  padding-left: 3px;
}
#textLibrarySearch .results ul li p:after {
  content: "";
  position: absolute;
  right: 14px;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #fff;
}
#textLibrarySearch .results ul li.active {
  background: #ffe6a5;
}
#textLibrarySearch .results ul li.active p:before, #textLibrarySearch .results ul li.active p:after {
  background: #ffe6a5;
}
#textLibrarySearch .results .actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px;
}

div.lightbox {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  background: white;
  width: 90%;
  max-width: 900px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 10px #666;
}
div.lightbox .titlebar {
  background: #2571b7;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 8px;
}
div.lightbox .titlebar span {
  cursor: pointer;
}
div.lightbox .content {
  padding: 12px;
}

.ql-container {
  height: auto !important;
  min-height: 167px !important;
}

.textarea .ql-container {
  display: flex;
  flex-direction: column;
}
.textarea .ql-container .ql-editor {
  flex: 1;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1em) scale(0.75) !important;
}

mat-label {
  background: #fff;
  padding: 0 5px;
}

.transparent .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0);
}

acc-main-loader {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
acc-main-loader .mat-progress-bar {
  height: 6px !important;
}

.quote-container .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.bottom-branding {
  background: linear-gradient(90deg, var(--nav-background) 0px, var(--nav-background) 20px, #fff 20px, #fff 15%);
  width: 100%;
  height: 80px;
  align-self: flex-end;
  border-radius: 0 0 15px 15px;
  transition-delay: 300ms;
}

.branding-neg-slant {
  height: 40px;
  background: linear-gradient(60deg, transparent 0%, white 50%, white 65%, #f2f8ff 65%, #f2f8ff 75%, #739dd9 75%, #739dd9 85%, #5381c1 85%, #5381c1 100%);
}

.branding-pos-slant {
  height: 40px;
  border-radius: 0 0 15px 15px;
  background: linear-gradient(120deg, transparent 0%, white 12%, white 65%, #f2f8ff 65%, #f2f8ff 75%, #739dd9 75%, #739dd9 85%, #5381c1 85%, #5381c1 100%);
}

.stripe-bg {
  background: linear-gradient(180deg, #fff 0vh, #fff 60vh, transparent 60vh, transparent 100vh), linear-gradient(194deg, #739cd9 0%, #739cd9 70%, #5381c1 70%, #5381c1 78%, #739cd9 78%, #739cd9 100%);
  border-radius: 0 0 50px 50px;
  min-height: calc(100vh - 110px);
  display: block;
  background-attachment: fixed;
}

mat-card {
  box-shadow: 0px 0px 13px 4px rgba(204, 204, 204, 0.4) !important;
}

.welcome-dialog-container .mat-dialog-container {
  padding: 0px;
  background-color: transparent !important;
}

.lock-features .mat-dialog-content {
  overflow-y: overlay !important;
  scroll-behavior: smooth !important;
}

.features-dialog .mat-dialog-container, .subscription-admin .mat-dialog-container {
  padding: 0px;
  background-color: transparent !important;
  box-shadow: none !important;
}

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

acc-main-quote-list {
  width: 100%;
}

.dob.field select.mat-input-element:not(:disabled), .state.field select.mat-input-element:not(:disabled) {
  z-index: 9;
  position: relative;
}

.no-results-dialog {
  width: 50%;
  max-width: 645px;
}

@media (max-width: 850px) {
  .no-results-dialog {
    width: auto;
  }
  .no-results-dialog mat-dialog-content {
    min-width: unset !important;
  }
}
.cdk-drag-preview {
  position: absolute !important;
}

.sent-items .filter .mat-form-field-flex {
  width: 83%;
}
.sent-items .filter .mat-form-field-wrapper {
  padding-bottom: 0;
}

.ql-tooltip {
  z-index: 5000;
  left: 50% !important;
  transform: translate(-50%, 50%) !important;
}

.copy-quote-button.mat-raised-button {
  color: white !important;
  font-size: 16px;
  width: 135px !important;
  float: right;
  margin-left: 10px;
}

.ql-tooltip.ql-editing::after {
  content: "Links should begin with http:// or https://";
  display: block;
  margin-top: 6px;
  text-align: center;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  padding: 0 0.75rem;
  background: var(--nav-background);
}

.shepherd-element .shepherd-text {
  min-height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  border-right: solid 10px var(--nav-background);
  border-left: solid 10px var(--nav-background);
  background: #fff;
}
.shepherd-element .shepherd-footer {
  display: grid;
  grid-template-areas: "buttons bg-top" "buttons bg-bottom";
  grid-template-columns: 50% 50%;
  grid-template-rows: 20px 20px;
  padding: 0 0 0 0.75rem;
  border-left: solid 10px var(--nav-background);
  background-color: #fff;
}
.shepherd-element .shepherd-footer::before {
  content: " ";
  height: 20px;
  width: 100%;
  grid-area: bg-top;
  background: linear-gradient(60deg, transparent 0%, white 50%, white 65%, #f2f8ff 65%, #f2f8ff 75%, #739dd9 75%, #739dd9 85%, #5381c1 85%, #5381c1 100%);
}
.shepherd-element .shepherd-footer::after {
  content: " ";
  height: 20px;
  width: 100%;
  grid-area: bg-bottom;
  background: linear-gradient(120deg, transparent 0%, white 12%, white 65%, #f2f8ff 65%, #f2f8ff 75%, #739dd9 75%, #739dd9 85%, #5381c1 85%, #5381c1 100%);
}
.shepherd-element .shepherd-footer button {
  grid-row: 1/span 2;
  margin-bottom: 0.75rem;
  width: 80px;
  background-color: var(--accent-color);
  color: #fff;
}
.shepherd-element .shepherd-footer .shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background-color: var(--accent-color);
}

.email-template-container .email-template video {
  width: 50%;
}

.shepherd-element.shepherd-has-title > .shepherd-arrow:before {
  background-color: #717171;
}

/*
.shep-highlight-elem {
	background-color: red;
}
*/