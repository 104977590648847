
$primary: var(--primary-color);
$accent: var(--accent-color);
$blue: #65a0cd;
$darkblue:  var(--primary-color);
$bluetext: #2571b7;
$orange: var(--accent-color);
$orangeHover: #fec226;
$grey: #c7c8ca;
$red: #be1e2d;
$green: #8bc53f;
$dark: #4d4d4d;
$darkText: #949297;
$light: #e0e0e0;
$bgColor: #f0f0f0;
$navBgColor: var(--nav-bg-color);
$navTextColor: var(--nav-text-color);

$loginGradientLight: #8bb5da;
$loginGradientDark: #4584ce;


$blue80pct: rgba(101,160,205,.8);
$orange80pct: rgba(246,146,30,.8);
$grey80pct: rgba(199,200,202,.8);
$red80pct: rgba(190,30,45,.8);
$green80pct: rgba(139,197,63,.8);
$dark80pct: rgba(77,77,77,.8);
$light80pct: rgba(224,224,224,.8);

$bodyCopyColor:#636466;

$logo: var(--logo);
$headerHeight: 110px;

$navClosedWidth: 108px;
$navOpenWidth: 285px;
$navTransitionTime: 0.5s;

@mixin bg {
    background-repeat: no-repeat;
}
@mixin bgCover {
    background-size:cover;
    background-position:center;
    @include bg;
}
@mixin bgContain {
	background-size:contain;
	background-position: center;
	@include bg;
}

@mixin fontAwesome {
	font-family: 'Font Awesome\ 5 Free';
	font-weight: 900;
}